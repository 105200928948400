<template>
  <div class="gyik">
    <div id="tabs">
      <!--
        Azért kell egy inaktív class, mert csak arra, az aktív lapra nem akarjuk alkalmazni a :hover stílust.
        Feltételezem, itt azért kell, és a navbarban meg nem, mert itt az svg-knek elemeire külön hivatkozunk class alapján, és ez valahogyan alacsonyabb rendű.
        Ha működik és nem elfogadhatatlanul spagetti, hagyd békén.
      -->
      <button :class="{tab_button: true, active_tab_button: activeTabIndex == 0, nonactive_tab_button: activeTabIndex != 0}" @click="setActiveTab(0)" title="Kutya">
        <div class="tab_button_bg"></div>
        <svg class="tab_button_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"> <path class="dog_icon_cls2" d="m43.87,30.84s-1.92-14.01-9.6-14.01c-1.13,0-2.08,0-2.9.02-.82-.01-1.78-.02-2.9-.02-7.68,0-9.6,14.01-9.6,14.01,0,0-1.21,5.33,0,13.72,1.21,8.39,9.6,9.31,9.6,9.31h5.81s8.39-.92,9.6-9.31c1.21-8.39,0-13.72,0-13.72Z"/> <path class="dog_icon_cls3" d="m39.37,19.41s-.26-9.6,5.04-9.28,13.24,2.72,13.24,2.72c0,0,6.35,1.44,6.35,6.08s-3.89,8.85-6.67,8.85-12.92-9.39-12.92-9.39c0,0-2-2.59-4,2.41l-1.04-1.4Z"/> <path class="dog_icon_cls3" d="m24.24,18.52s-3.59-3.27-8.23-3.27S3,21.12.44,29.55s6.99,10.72,6.99,10.72c0,0,2.83,1.49,4.16-4.27s3.91-17.44,6.75-17.44,4.29,1.82,4.29,1.82l1.62-1.86Z"/> <ellipse class="dog_icon_cls3" cx="28.97" cy="26.16" rx="1.28" ry="2.64"/> <ellipse class="dog_icon_cls3" cx="34.19" cy="26.16" rx="1.28" ry="2.64"/> <path class="dog_icon_cls3" d="m31.37,41.12s12.06-7.89,0-7.89,0,7.89,0,7.89Z"/> <line class="dog_icon_cls1" x1="31.37" y1="40.1" x2="31.37" y2="45.28"/> <path class="dog_icon_cls1" d="m37.92,45.28s-2.92,2.93-6.55-.27"/> <path class="dog_icon_cls1" d="m24.82,45.28s2.92,2.93,6.55-.27"/> </svg>
      </button>
      <button :class="{tab_button: true, active_tab_button: activeTabIndex == 1, nonactive_tab_button: activeTabIndex != 1}" @click="setActiveTab(1)" title="Macska">
        <div class="tab_button_bg"></div>
        <svg class="tab_button_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"> <path class="cat_icon_cls2" d="m49.42,25.83v-12.47l-8.65,6.26s-3.89-.88-8.77-.89c-4.87,0-8.77.89-8.77.89l-8.65-6.26v12.47c-5.04,3-4.07,9.16-4.07,9.16,1.91,13.64,13.51,15.81,21.49,15.65,7.98.17,19.58-2.01,21.49-15.65,0,0,.97-6.16-4.07-9.16Z"/> <path class="cat_icon_cls2" d="m19.67,28.98s7.53-3.38,9.21,4.06c0,0-7.28,3.32-9.21-4.06Z"/> <path class="cat_icon_cls1" d="m23.82,34.12s-3.4-4.4,1.05-6.46c0,0,2.88,4.36-1.05,6.46Z"/> <path class="cat_icon_cls2" d="m44.28,28.98s-7.53-3.38-9.21,4.06c0,0,7.28,3.32,9.21-4.06Z"/> <path class="cat_icon_cls1" d="m40.14,34.12s3.4-4.4-1.05-6.46c0,0-2.88,4.36,1.05,6.46Z"/> <path class="cat_icon_cls1" d="m32,39.26s-6.91-4.48,0-4.48,0,4.48,0,4.48Z"/> <line class="cat_icon_cls2" x1="32" y1="37.78" x2="32" y2="42.98"/> <path class="cat_icon_cls2" d="m32,42.06s.21,1.88,3.07,1.88,2.41-2.14,2.41-2.14c0,0,.3-1.76-2.73-1.76"/> <path class="cat_icon_cls2" d="m32,42.06s-.21,1.88-3.07,1.88c-2.87,0-2.41-2.14-2.41-2.14,0,0-.3-1.76,2.73-1.76"/> <line class="cat_icon_cls2" x1="24.23" y1="37.58" x2="15.88" y2="35.49"/> <line class="cat_icon_cls2" x1="24.23" y1="39.85" x2="15.88" y2="41.6"/> <line class="cat_icon_cls2" x1="39.68" y1="37.58" x2="48.02" y2="35.49"/> <line class="cat_icon_cls2" x1="39.68" y1="39.85" x2="48.02" y2="41.6"/> </svg>
      </button>
    </div>
    <div id="content">
      <div id="dog_content" v-show="activeTabIndex == 0">
        <GYIKAccordion headerText="Féreghajtás">
          <p>
            Ha kutya került a családba, minden esetben <b>a legelső teendő az állat féreghajtása</b>. Én akkor is ajánlom, ha a származási helyen már
            kapott féreghajtót az állat, hiszen az a biztos, amit látunk. Gyakorlatilag bajt nem tudunk csinálni vele, viszont az egész vakcinázás
            hatékonyságát veszélyeztetjük, ha férges a kedvencünk, nem is beszélve arról, hogy a gazdi számára is potenciális veszélyt jelent a
            férgesség. <b>A féreghajtó mindig az állat testtömegének megfelelő legyen!</b> Kisebb állatoknál pasztát célszerű használni, nagyobbaknál
            általában <b>10 testtömegkilogrammonként 1 tabletta</b> adagban valamelyik széles spektrumú tablettát. Rendelkezésre állnak rácsepegtetős (ún. spot-on) féreghajtók is.
            Ezek a készítmények általában kissé költségesebbek, viszont könnyebb az alkalmazásuk, mint a szájon át adandó termékeknek. Ha a féreghajtás
            után féregízek, spagettiszerű, hosszú szálak vagy uborkamagszerű féregpeték ürülnek, akkor a féreghajtást két hét múlva meg kell ismételni!
            Bizonyos féregtípusnál a fentiektől eltérő protokollok is előfordulhatnak. A féreghajtók nem hosszú hatású készítmények, ezért a környezet
            terheltségének függvényében visszafertőződés lehet. A veszettség elleni védőoltáskor <b>kötelező évente egyszer a féreghajtás</b>, de
            legalább félévente ajánlott. Ha kisgyermek van, vagy igen szoros kapcsolatban állunk a kutyával (puszilgatás), én negyedévente ajánlom a
            féreghajtók alkalmazását. A szívférgesség elleni védekezés részeként negatív teszt esetén a szúnyogszezonban havonta szükséges a védekezést
            ismételni. A szívférgességről részletesebben
            <a href="/publicRes/szivfergesseg.pdf" target="_blank">itt&nbsp;olvashat</a>.
          </p>
        </GYIKAccordion>

        <GYIKAccordion headerText="Vakcinázás">
          <p>
            Fontos tisztázni a kutya hátterét. Amennyiben jól vakcinázott állományból származik, 8 hetes koráig az anyai ellenanyagok védik a kölyköket.
            Ha nem ismert a háttér, vagy kifejezetten érzékeny fajtáról van szó (pl. a rottweilerkölykök igen gyenge immunrendszerűek), 6 hetesen lehet elkezdeni
            a védőoltásokat. Az oltások között minimum két hétnek kell eltelnie. A kölyökkutyák számára a legveszélyesebb betegség talán a
            <b>parvovírusos bélgyulladás</b>. Jellegzetes tünetei a hányás és a kifejezetten bűzös, véres hasmenés. A kis szervezet a rendkívüli
            folyadékvesztést általában nem tudja tolerálni, <b>nagy az elhullási arány még állatorvosi gyógykezelés mellett is</b>. Ennek megelőzésére
            elsőként a vakcinázási programban a parvovírusos bélgyulladás elleni vakcinát használjuk. Két hét múlva egy <b>kombinált vakcina</b>
            alkalmazása ajánlott, ami 5-7 veszélyes betegség elleni komponenst tartalmaz: szopornyica, fertőző májgyulladás, leptospirozis
            (c&nbsp;és&nbsp;i), parainfluenza, parvovírus és adenovírus. Mindkét vakcinát ismételni kell a megfelelő alapimmunitás elérése érdekében.
            Nagyon fontos, hogy a második kombinált oltást a kutya 3 hónapos kora után kapja. A fenti vakcinák az állat saját védekezőrendszerét
            aktiválják, tehát nem közvetlenül védenek a fertőző betegségek ellen, hanem a szervezetet késztetik ellenanyag-termelésére. Ezért fontos
            szem előtt tartani, hogy a vakcina beadása után el kell telni kb. 2 hétnek, hogy a védettség kialakuljon. Fentiek értelmében a 4. oltás után
            2 héttel tekinthető az állat védettnek a felsorolt betegségekkel szemben. Lehetőség szerint addig az állatot védetten kell tartani, nem
            szabad kutyák által gyakran látogatott helyekre vinni (kutyafuttató). A fenti betegségek elleni védekezés nem kötelező, de mindenképpen
            ajánljuk. Az állat életére általában nem veszélyes betegségek a <b>coronavírusos hasmenés</b>, <b>kennelköhögés</b>, a <b>Lyme-kór</b>,
            illetve a <b>gombás megbetegedések</b>. Ezek ellen is létezik vakcina. Az alapvakcinázás után évente emlékeztetőoltásban kell az állatokat
            részesíteni. Felnőttkorban (egy éves kor felett) alkalmazható olyan vakcina, ami egyben tartalmazza a kombinált oltás és a veszettség elleni
            komponenst is. 3 hónapos kor felett <b>kötelező</b> a sorbanövő ebek <b>veszettség</b> elleni védőoltása, <b>chipezése</b>, valamint
            <b>féreghajtása</b>. Az első veszettség elleni oltást 6 hónapon belül meg kell ismételni, utána évente egyszer kell emlékeztetőoltást
            kapnia kedvencünknek.
          </p>
          <hr>
          <h2>Összefoglalva:</h2>
          <ul>
            <li><b>6. hét:</b> Parvovírusos bélgyulladás elleni vakcina</li>
            <li><b>8. hét:</b> Kombinált oltás<br></li>
            <li><b>10. hét:</b> Parvovírusos bélgyulladás elleni vakcina</li>
            <li><b>12. hét:</b> Kombinált oltás<br></li>
            <li><b>14. hét:</b> Veszettség elleni oltás + mikrochip beültetése, regisztrációja</li>
            <li><b>A 14. heti oltás után 6 hónapon belül:</b> Veszettség elleni oltás</li>
            <li><b>Az állat egy éves korában:</b> Kombinált emlékeztetőoltás</li>
          </ul>
        </GYIKAccordion>

        <GYIKAccordion headerText="Kullancs">
          <p>
            Zala vármegyében rendkívül sok kullancs van. A kullancsok többféle betegséget terjesztenek. Talán legismertebb az agyhártyagyulladás, ami
            viszont kutyákban ritkán fordul elő. Nagyobb veszélyt jelent az embereket is megbetegítő Lyme-kór, ami hosszú lappangási idő után jelentkező
            izületi gyulladásokban, körülírt bőrpírral jelentkező, gyógyszeresen kezelhető betegség. A legnagyobb veszély a kutyákra a kullancsok által
            terjesztett egysejtű parazita, a babesia canis. Ez a kórokozó a kullancs vérszívásakor kerül be a kutya vérpályájába, és a vörösvértesteket
            károsítja. A szétrobbanó vörösvértestektől próbál a szervezet megszabadulni, de néhány nap alatt a kutya mája, veséje károsodik, az állat
            besárgul, összeomlik a szervezet. Van ellenszere a betegségnek, de a gyógykezelés eredményessége attól függ, mennyi idő telik el a
            fertőződéstől a beavatkozás megkezdéséig. Ha időben észleljük a bajt, néhány szuri beadása után másnapra az állatnak semmi baja sincs, ha
            viszont későn kezdünk beavatkozni, a másodlagos máj- és vesekárosodás terápiája (intenzív infúziós terápia)
            <b>gyógykezelés mellett is kétséges</b>. Fontos tehát, hogy ha kullancs volt az állatban, vagy olyan helyen sétáltattuk, ahol kullancs
            előfordulhat, és utána 2 napon belül bágyadt az állat, <b>azonnal forduljunk állatorvoshoz!</b> A betegség magas, 40°C feletti lázzal,
            jellegzetesen sötét, kávészerű vizelettel, később besárgult nyálkahártyákkal jelentkezik. Mindhárom betegség megelőzhető, ha megvédjük
            állatainkat attól, hogy a kullancsok vért szívjanak belőlük.
          </p>
          <p><b>Erre négyféle megelőzési mód alkalmazható:</b></p>
          <ol>
            <li>Rövid hatású fürösztő- vagy porzószerek. Viszonylag olcsók, de a hatástartamuk miatt gyakran (hetente) kell alkalmazni őket, alkalmazásuk munkaigényes.</li>
            <li>Spot-on (rácsöppentős) készítmények. Használatuk kényelmes, szagtalan. Általában egy hónapig védenek kullancs ellen, bolha ellen némelyik kissé hosszabban is hat.</li>
            <li>Jó minőségű nyakörvek. 5-8 hónapig védenek bolha és kullancs ellen is. Havi költségre lebontva ezek olcsóbbak, mint a spot-on készítmények. Figyelembe kell azt is venni, hogy ha a kutya vadászni jár, erdőben sétál, bokrokon felakadhat, elveszítheti, stb.</li>
            <li>Manapság praktikus választás a tablettás védelem, ami gyártmánytól függően 1-3 hónapos védettséget ad bolha és kullancs, valamint esetlegesen egyéb kórokozók ellen is.</li>
          </ol>
        </GYIKAccordion>

        <GYIKAccordion headerText="Bolha">
          <p>
            Nem olyan veszélyes, mint a kullancs, viszont gyakrabban fordul elő. Kellemetlen csípéseiken kívül allergiás bőrgyulladást is okozhatnak
            viszonylag gyakran. Ez jellegzetesen a faroktő környékén alakul ki, nedvező, piros folt formájában, amit az állat gyakran rág, ezért
            szőrtelenné válik. Megelőzésre célszerű olyan készítményt használni, ami a kullanccsal együtt a bolhákat is távol tartja.
            (Lásd: előző fejezet)
          </p>
        </GYIKAccordion>

        <GYIKAccordion headerText="Tetű">
          <p>
            Viszonylag ritkábban, főleg elhanyagolt tartáskörülmények esetén alakul ki tetvesség. A betegség könnyen, gyorsan gyógykezelhető,
            általában rácsöpögtetős készítmény alkalmazásával. 
          </p>
        </GYIKAccordion>

        <GYIKAccordion headerText="Atka">
          <p>
            Kutyák esetében közepesen gyakran jellemző, külső élősködő. A szőrtüsző mélyén megtelepedő demodex makacs, hosszabb ideig tartó
            gyógykezelést igényel, míg a fülben előforduló rühatkák könnyebben kezelhetőek. 
          </p>
        </GYIKAccordion>
      </div>
      <div id="cat_content" v-show="activeTabIndex == 1">
        <GYIKAccordion headerText="Féreghajtás">
          <p>
            A féreghajtást 21 napos kismacskáknál kell elkezdeni, és az anyaállatot is velük egy időben kell kezelni. A kezelést 14 naponta az
            elválasztás utáni 14.&nbsp;napig kell folytatni.
          </p>
          <p>
            Felnőtt macskákat évente legalább négyszer (három havonta) célszerű féreghajtani. Amennyiben kisgyerekek és/vagy immunszupresszált betegek
            vannak a családban, úgy havonkénti kezelés javasolt.
          </p>
          <p>
            Kölyökkorban célszerű pasztás készítmények alkalmazása, felnőttkorban tabletta adása. Mivel sok cicának gondot okoz a tabletta beadása,
            léteznek már spot-on (nyakracseppentős készítmények) formájában is szélesspektrumú féreghajtók.
          </p>
        </GYIKAccordion>

        <GYIKAccordion headerText="Vakcinázás">
          <p>
            Macskák számára léteznek kombinált védőoltások, melyek az alábbi betegségekkel szemben nyújthatnak védelmet: macska-rhinotracheitis,
            calicivirus, panleukopenia, leukózis és a Chlamydia felis; illetve immunizálhatjuk őket egykomponensű vakcinával veszettség ellen is.
            Cicák esetében egyik vakcina sem kötelező (amennyiben nem utazunk velük külföldre), de erősen ajánlott a tartási körülmények
            figyelembevételével. Kijárós macskák esetében mindkét típusú oltás, míg lakásban tartott egyedeknél a kombinált oltás ajánlott. 
          </p>
          <p>
            Kölyökállatoknál az alapimmunizálást 8-9 hetes korban kezdjük kombinált vakcinával, majd 3-4 hetente kapnak 1-2 ismétlőoltást az állat
            min.&nbsp;16 hetes koráig. A kombinált oltási sort követően a veszettség betegséggel szemben már egy oltás is elegendő a megfelelő
            alapimmunitás eléréséhez. 
          </p>
          <p>
            A kölyökkori oltási sort követően mind a kombinált, mind pedig a veszettség elleni vakcinákat évente egyszer szükséges ismételni. 
          </p>
          <p>
            Fontos megjegyezni, hogy ismeretlen immunstátuszú egyedeknél a kombinált oltást megelőzően célszerű az állatot szűrni macskaleukózisra
            vonatkozóan.
          </p>
          <hr>
          <h2>Összefoglalva:</h2>
          <ul>
            <li><b>9. hét:</b> Kombinált oltás</li>
            <li><b>12. hét:</b> Kombinált oltás</li>
            <li><b>15. hét:</b> Veszetség elleni oltás</li>
          </ul>
        </GYIKAccordion>

        <GYIKAccordion headerText="Mikrochip és utazás külföldre">
          <p>
            Kutyáktól eltérően macskák részére a veszettség oltáshoz hasonlóan a mikrochip beültetése sem kötelező, jogszabály nem írja elő, azonban
            igen hasznosnak bizonyulhat, ha cicánk kijárós, vagy hajlamos elcsatangolni. Amennyiben kedvencünkkel külföldi utazást tervezünk,
            szükségünk lesz mindenekelőtt állatútlevélre, amely azonban csak veszettség elleni oltással rendelkező és azonosítható állat számára
            állítható ki. Így tehát ebben az esetben mindenképp szükséges mikrohippel ellátni az állatot. Állatútlevelet állatorvosunknál tudunk
            igényelni. Fontos tudnivaló továbbá, hogy az útlevél a veszettségoltás beadását követő 21 nap elteltével lesz érvényes, így érdemes időben
            előre gondoskodni a dokumentumokról és a szükséges oltásokról. Úticéltól függően ezeken felül további vizsgálatok lehetnek szükségesek
            (pl.&nbsp;igazolt féreghajtás, veszettség titer vizsgálat, stb.), ezekről is érdemes előre tájékozodni.
          </p>
        </GYIKAccordion>

        <GYIKAccordion headerText="Ivartalanítás">
          <p>
            Amennyiben állatánál nem a tenyésztés a fő tartási cél, érdemes ivartalanítani. Mind a nőstény, mind a kandúr cicák esetében 6-8 hónapos
            kortól ajánlott a műtéti beavatkozás. Ivartalanítással nemcsak a nem kívánt szaporulat előzhető meg, hanem számos betegség és rossz szokás
            is. 
          </p>
          <p>
            Nőstény cicák az ivarérést követően (kb. 7-8 hónapos kor) az év nagy részében szinte folyamatosan ivarzanak, melynek idején a legtöbb
            nőstény cica viselkedése gyökeresen megváltozik. Hangos nyávogással “hívja” a kandúrokat, nyugtalanná válik, sokat hempereg, étvágya
            csökken. Ez mind a cica, mind a gazda számára is megterhelő időszak. Ivartalanítás következtében csökken a kockázata az emlőmirigy-daganat
            kialakulásának, a petefészekciszták és -daganatok nem jelentenek valódi kockázatot, sokkal kisebb az esély méhfertőzések, gennyes
            méhgyulladás kialakulására. 
          </p>
          <p>
            Kandúrok esetében is számos előnnyel jár az ivartalanítás. A pubertás után, 8-9 hónapos korban a kandúr cicák számos, nem kívánatos
            viselkedésformát, “rossz szokást” vehetnek fel. Gyakran a lakáson belül is területük megjelölésére törekszenek, elsősorban
            vizeletspricceléssel, ami kifejezetten kellemetlen, nehezen eltávolítható szagot hagy maga után. Ha a kandúrnak lehetősége van rá, egyre
            távolabb, hosszú napokra csavarog el a háztól, hogy minél nagyobb területet alakíthasson ki magának, azonban ezekért a területekért
            gyakran más cicákkal is meg kell küzdeniük, aminek gyakran nehezen gyógyuló sebek, tályogok a következményei. Számos gyógyíthatatlan
            fertőző betegség is (pl. macska-AIDS) karmolás, harapás útján terjed, így ivartalanítással a fertőződés esélye jelentősen csökken,
            valamint a hererák kockázata is megszűnik. 
          </p>
        </GYIKAccordion>

        <GYIKAccordion headerText="Külső élősködők elleni védelem">
          <p>
            Kutyákhoz hasonlóan a macskáknak is számos külső élősködőjük lehet: bolha, kullancs, fülatka, szőrtetű. 
          </p>
          <p>
            A bolhafertőzöttség roppant kellemetlen, viszkető érzés az állatnak. Súlyos mértékű fertőzöttség esetén tömeges vérszívásuk akár
            vérfogyottságot is okozhat kedvencünknek. Csípéseikkel azonban nemcsak viszketést váltanak ki, de számos cica és kutya szervezete
            allergiás reakcióba lép a bolhanyállal, amely égető, viszkető bőrgyulladás formáját ölti leggyakrabban a hát-ágyéki szakaszon, faroktő
            környékén. Ezenfelül a bolhák gyakori köztigazdáik az uborkamagképű galandféregnek, ami nemcsak a cicánkra, de az emberre is veszélyes
            lehet. Megelőzésre célszerű lehet akár olyan kombinált készítményeket is alkalmazni, amely kullancs ellen is védelmet biztosít. 
          </p>
          <p>
            A kullancsok által terjesztett betegségek sajnos a cicákat is érintik. Ritkán megfertőződhetnek Lyme-kórral, melynek tünetei közé
            sorolhatunk ízületi gyulladásokat, vándorló bőrpírt, váltakozó lázat. Ennél gyakoribb előfordulású betegség azonban a macskák fertőző
            anaemiája, vagy macskák mycoplasmosisa. Ezt a betegséget a Mycoplasma haemofelis nevű kórokozó okozza, mely a macskák vörösvérsejtjein
            élősködik. A fertőzött kullancsok terjesztik egyik állatról a másikra, de az anyamacska is megfertőzheti kicsinyeit, sőt nem
            ivartalanított, verekedő kandúrok harapással is fertőzhetik egymást. Főbb tünetei: magas láz, étvágytalanság, halvány nyálkahártyák,
            sárgaság. Egyéb társfertőzések (mint a macska-AIDS vagy leukózis) súlyosbíthatják a betegség lefolyását. 
          </p>
          <p>
            Ha cicánk gyakran rázza a fejét, vakarja a fülét, mindenképp gyanakodni kell fülatkára is. A legtöbb problémát ez az élősködő okozza, mely
            a fül külső hallójárataiban telepszik meg. A fülatka tűfejnyi, fényes, mozgékony pókszabású, amely méretéből adódóan szabad szemmel nem
            látható, csak a fülkagyló járataiban megjelenő nagy mennyiségű, ragadós, sötétbarna, kávézaccra emlékeztető váladék jelzi jelenlétét.
            Elhanyagolt esetben súlyos fülgyulladás és egyensúlyzavar is bekövetkezhet. 
          </p>
          <p>
            A szőrtetvek egy jól ápolt cica bundájában ritkán fordulnak elő, azonban ha felüti a fejét, akkor kétféle tetűre gyanakodhatunk: az egyik
            a rágótetű, a másik a szívótetű. Többnyire a macska fején telepednek meg, de tanyát verhetnek a testén bárhol. A súlyosan eltetvesedett
            állatnál vérszegénység léphet fel. Szabad szemmel nehezen észrevehetőek, sárgásfehér, igen apró teremtmények, de ha macskánk szőrzetét
            gyakran rágcsálja, esetleg szőrhullás, korpásodás jelentkezik, ez felhívhatja a figyelmet a fertőzöttségre. 
          </p>
          <p>
            Védekezésre már számos kombinált készítmény áll rendelkezésre, amely akár az összes előbb felsorolt élősködő ellen egyszerre hatásos.
            Formáját tekintve a leggyakoribb készítmények az ún. spot-on szerek, amelyet a macskák nyakszirtjénél szőrt széthúzva kell a nyak bőrére
            cseppenteni.
          </p>
        </GYIKAccordion>
      </div>
      <div id="invalid_index_content" v-if="activeTabIndex != 0 && activeTabIndex != 1" style="color: red;">
        Ha ezt látja, hiba lépett fel GYIK lapok közötti navigáláskor (ismeretlen lap index). Kérem
        írja meg az <router-link to="/elerhetoseg">elérhetőség oldalon</router-link> lévő e-mail
        címre, hogy ezt látta, valamint, hogy ezt a hibát hogyan idézte elő és (lehetőség szerint)
        egy screenshotot a vizsgáló (F12 gomb) konzol ablakáról. Amennyiben ezt a hibát nem a lapok
        és a kontent közötti eltérő navigációs index okozta, ha most rákattint egy lapra, meg
        kellene oldódnia a hibának. Elnézést kérünk a kellemetlenségért.
      </div>
    </div>
  </div>
</template>

<script>
import GYIKAccordion from '@/components/GYIKAccordion.vue';

export default {
  components: {
    GYIKAccordion
  },
  data() {
    return {
      activeTabIndex: 0
    }
  },
  methods: {
    setActiveTab(tabIndex) {
      if (tabIndex == this.activeTabIndex) return;
      this.activeTabIndex = tabIndex;
    }
  }
}
</script>

<style scoped>
  .gyik {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    max-width: 1500px;
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;
  }

  #tabs {
    width: 100%;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .tab_button {
    display: inline-block;
    position: relative;
    width: 75px;
    height: 70px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .tab_button_icon {
    display: inline-block;
    position: relative;
    width: 64px;
    z-index: 2;
  }

  .tab_button_icon * {
    transition: stroke 0.2s, fill 0.2s;
  }

  .tab_button .tab_button_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 100%;
    height: 0%;
    background-color: rgb(255, 255, 255);  
    transition: height 0.2s, margin-top 0.2s;
    z-index: 1;
  }

  .active_tab_button .tab_button_bg {
    height: 100%;
    margin-top: 0%;
  }

  #content {
    background-image: url("~@/assets/backgrounds/bgWhite.png");
    background-color: white;
    width: 100%;
    height: min-content;
    border-radius: 0 0 15px 15px;
  }

  #dog_content, #cat_content, #invalid_index_content {
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  /* Interaktív stílusok */

  .active_tab_button .dog_icon_cls1, .active_tab_button .dog_icon_cls2, .active_tab_button .cat_icon_cls2 {
    fill: none;
    stroke: #002930;
  }

  .active_tab_button .dog_icon_cls3, .active_tab_button .cat_icon_cls1 {
    fill: #002930;
  }
  
  .nonactive_tab_button:hover .dog_icon_cls1, .nonactive_tab_button:hover .dog_icon_cls2, .nonactive_tab_button:hover .cat_icon_cls2 {
    fill: none;
    stroke: #f0f0f0;
  }

  .nonactive_tab_button:hover .dog_icon_cls3, .nonactive_tab_button:hover .cat_icon_cls1 {
    fill: #f0f0f0;
  }

  /* SVG alap stílusok */

  .dog_icon_cls1 {
    stroke-linecap: round;
  }

  .dog_icon_cls1, .dog_icon_cls2 {
    fill: none;
    stroke: #58c5c2;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }

  .dog_icon_cls3 {
    fill: #58c5c2;
  }

  .cat_icon_cls1 {
    fill: #58c5c2;
  }

  .cat_icon_cls2 {
    fill: none;
    stroke: #58c5c2;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }
</style>