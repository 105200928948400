import { createRouter, createWebHistory } from 'vue-router'

import KezdolapView from "../views/KezdolapView.vue"
import BemutatkozasView from "../views/BemutatkozasView.vue"
import SzolgaltatasokView from "../views/SzolgaltatasokView.vue"
import TudomanyosView from "../views/TudomanyosView.vue"
import GYIKView from "../views/GYIKView.vue"
import CeginfoView from "../views/CeginfoView.vue"
import ElerhetosegView from "../views/ElerhetosegView.vue"
import NotFoundView from "../views/NotFoundView.vue"

const routes = [
  {
    path: '/',
    name: "{root}",
    component: KezdolapView
  },
  {
    path: '/kezdolap',
    name: "Kezdőlap",
    component: KezdolapView
  },
  {
    path: '/bemutatkozas',
    name: 'Bemutatkozás',
    component: BemutatkozasView
  },
  {
    path: '/szolgaltatasok',
    name: 'Szolgáltatások',
    component: SzolgaltatasokView
  },
  {
    path: '/tudomanyos_munkak',
    name: 'Tudományos munkák',
    component: TudomanyosView
  },
  {
    path: '/gyik',
    name: 'GYIK',
    component: GYIKView
  },
  {
    path: '/ceginfo',
    name: 'Céginfó',
    component: CeginfoView
  },
  {
    path: '/elerhetoseg',
    name: 'Elérhetőség',
    component: ElerhetosegView
  },
  {
    path: '/szolgaltatasok',
    name: 'Szolgáltatások',
    component: SzolgaltatasokView
  },
  {
    path: '/:notFound',
    name: '404',
    component: NotFoundView
  }
]

const router = createRouter({
  base: "",

  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Valószínűleg végig a `{root}` lesz az egyetlen speciális című oldal, így egy if is elég lenne, de így kicsit inline-abb, hogy case-ek a
  // speciálisak és a defaultok a "muglik". A többi speciális oldal ugyan így, külön case-ekben lenne.
  switch (to.name) {
    case "{root}":
      document.title = "Állatszerviz Kft.";
      break;

    default:
      document.title = to.name + " • Állatszerviz Kft.";
      break;
  }

  next();
})

export default router
