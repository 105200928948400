<template>
  <div id="image_preview" v-if="isOpen">
    <div id="content">
      <div id="top_bar">
        <h1 id="title">{{imageAlt}}</h1>
        <div id="buttons">
          <a :href="imagePath" target="_blank" id="open_button" title="Kép megnyitása új lapon" style="padding: 2px 0 0 4px">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16" id="IconChangeColor">
              <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" id="mainIconPathAttribute"/>
              <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" id="mainIconPathAttribute"/>
            </svg>
          </a>
          <button id="close_button" title="Kép előnézet bezárása" style="margin: 0 5px; padding: 3px 0 0 0" @click="close()">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" id="IconChangeColor">
              <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" id="mainIconPathAttribute"/>
              <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" id="mainIconPathAttribute"/>
            </svg>
          </button>
        </div>
      </div>
      <div id="image_container">
        <img :src="imagePath" :alt="imageAlt">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    imagePath: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
  },
  computed: {
    imageName() {
      return this.imagePath.split('/').pop();
    }
  }
}
</script>

<style scoped>
#image_preview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.75);
}

#image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

img {
  max-width: 100%;
  max-height: calc(100vh - 40px); /* Tetején lévő cuccok miatt kell */
}

#title {
  display: inline-block;
  margin: 0 0 5px 5px;
  color: rgba(255, 255, 255, 0.75);
}

#buttons {
  float: right;
}

#buttons * {
  display: inline-block;
}

#close_button, #open_button {
  width: 30px;
  height: 30px;
  margin-top: 5px;
  background: none;
  border: none;
  padding: 0;
  color: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
  height: max-content;
}

#close_button:hover, #open_button:hover {
  color: white;
  background-color: hsla(0, 0%, 100%, 0.25);
}

#close_button:active, #open_button:active {
  color: black;
  background-color: #58c5c2;
}
</style>