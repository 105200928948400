<template>
  <div class="not_found">
    <img id="left_animal" src="../assets/404Page/404Dog.png" alt="Nagyon elbújt kutya">
    <div id="text">
      <h1>404</h1>
      <h2>Az oldal nem található.</h2>
      <hr>
      <router-link id="back_to_home_link" to="/"><i class='fa-solid fa-house-chimney'/> Vissza a kezdőlapra</router-link>
      <hr>
      <p>Ha úgy gondolja, ez az oldal hibájából történt, kérjük, jelentse be a hibát (és hogy hogyan idézte elő) az <router-link to="/elerhetoseg">elérhetőség oldalon</router-link> lévő e-mail címre!</p>
    </div>
    <img id="right_animal" src="../assets/404Page/404Cat.png" alt="Nagyon elbújt macska">
  </div>
</template>

<style scoped>
  .not_found {
    background-image: url("~@/assets/backgrounds/bgWhite.png");
    background-color: white;
    margin-top: 50px;
    padding: 15px;
    width: 700px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    box-shadow: black 0 0 15px;
    text-shadow: white 0 0 2px;
    position: relative; /* Reszponzív nézetben állatok absolute poziciójához kell */
  }

  #text {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 360px;
  }

  h1 {
    margin: 0;
    font-size: 72px;
    text-align: center;
  }

  h2 {
    margin: 0;
    text-align: center;
  }

  hr {
    border-color: hsl(0, 0%, 75%);
    border-style: solid;
    color: hsl(0, 0%, 75%);
    margin: 5px;
  }

  #back_to_home_link {
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 5px;
    text-decoration: none;
    color: #00b4b0;
    width: max-content;
    font-weight: bold;
    text-shadow: white 0 0 3px;
  }

  #back_to_home_link:hover {
    text-shadow: hsl(178, 48%, 25%) 0 0 5px;
    background-color: rgba(88, 197, 193, 0.5);
    color: white;
  }

  #back_to_home_link:active {
    background-color: #58c5c2;
    text-shadow: none;
    color: black;
  }

  p {
    margin: 0;
    text-align: center;
  }

  p a {
    color: #00b4b0;
  }

  p a:hover {
    text-shadow: hsl(178, 48%, 25%) 0 0 5px;
    background-color: rgba(88, 197, 193, 0.5);
    color: white;
    text-decoration: none;
  }

  p a:active {
    background-color: #58c5c2;
    text-shadow: none;
    color: black;
    text-decoration: none;
  }

  #left_animal {
    display: inline-block;
    margin-right: 5px;
  }

  #right_animal {
    display: inline-block;
    margin-left: 15px;
  }

  @media only screen and (max-width: 770px) {
    .not_found {
      width: max-content;
      padding: 0 5px;
      padding-bottom: 260px; /* 245px magas a magasabb, kutya kutya kép, így kis paddingal pont elfér */
    }

    #left_animal, #right_animal {
      display: block;
      position: absolute;
      bottom: 0;
    }

    #left_animal {
      left: 10%;
    }

    #right_animal {
      right: 10%;
    }
  }

  @media only screen and (max-width: 380px) {
    .not_found {
      width: 100%;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }

    #text {
      display: block;
    }

    #left_animal {
      left: 0;
    }

    #right_animal {
      right: 0;
    }
  }
</style>