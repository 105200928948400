<template>
  <div id="non_footer_container">
    <EventPopup v-if="eventPopupOpen" @closed="eventPopupOpen = false;"/>
    <Navbar :currentPageName="currentPageName"/>
    <router-view/>
  </div>
  <Footer/>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import EventPopup from '@/components/EventPopup.vue'

export default {
  name: 'HomeView',
  components: {
    Navbar,
    Footer,
    EventPopup
  },
  data() {
    return {
      eventPopupOpen: false,
      currentPageInternalName: ""
    }
  },
  watch:{
    /* Ha router-linkekkel közlekedünk az oldalak között, nem ugrik fel az oldal legtetjére, így
     pl. ha a footerből navigálunk, megzavaró lehet, hogy "semmi nem változik", szóval ez a kód ezt megoldja  */
    $route (to, from){
      window.scrollTo(0, 0);
      this.currentPageInternalName = to.name
    }
  },
  computed: {
    currentPageName() {
      switch (this.currentPageInternalName) {
        case "{root}":
          return "Kezdőlap"

        default:
          return this.currentPageInternalName
      }
    }
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: hsl(189, 29%, 20%);
  height: 100%;
  background-image: url("~@/assets/backgrounds/bg.png");
  background-color: #136c7b;
}

#non_footer_container {
  height: max-content;
  min-height: 100vh
}

/* Scrollbar */

/* Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: #58c5c2 #002930;;
}

/* Chromium */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #002930;
}

body::-webkit-scrollbar-thumb {
  background-color: #58c5c2;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #3ea09e;
}

body::-webkit-scrollbar-thumb:active {
  background-color: #005F5D;
}

/* Kijelölt szöveg */

::selection, ::-moz-selection {
  color: white;
  text-shadow: none;
  background: #58c5c2;
}
</style>
