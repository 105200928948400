<template>
  <div class="bem_person_m">
    <!-- <div class="tap_for_info" ref="tapForInfo" v-if="!tapForInfoShown">
      <div class="content_container">
        <i class="icon fa-solid fa-hand-pointer"/>
        <p class="text">Koppints ide több információért!</p>
      </div>
    </div> -->
    <div class="img_container">
      <img :src="require('@/assets/employees/' + image)" :alt="name">
      <p v-if="html != ''" class="tap_for_info">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M128 40c0-22.1 17.9-40 40-40s40 17.9 40 40V188.2c8.5-7.6 19.7-12.2 32-12.2c20.6 0 38.2 13 45 31.2c8.8-9.3 21.2-15.2 35-15.2c25.3 0 46 19.5 47.9 44.3c8.5-7.7 19.8-12.3 32.1-12.3c26.5 0 48 21.5 48 48v48 16 48c0 70.7-57.3 128-128 128l-16 0H240l-.1 0h-5.2c-5 0-9.9-.3-14.7-1c-55.3-5.6-106.2-34-140-79L8 336c-13.3-17.7-9.7-42.7 8-56s42.7-9.7 56 8l56 74.7V40zM240 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304zm48-16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304c0-8.8-7.2-16-16-16zm80 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304z"/></svg>
        Bemutatkozás
      </p>
    </div>
    <div class="info">
      <h1>{{name}}</h1>
      <h2>{{role}}</h2>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  props: [
    "image", // A `@/assets/employees/` mappához relatív, kiterjesztést tartalmazó fájlnév kell
    "name",
    "role",
    "html"
  ],
  data() {
    return {
      tapForInfoShown: store.bemutatkozasView.tapForInfoShown
    }
  }
}
</script>

<style>
.bem_person_m {
  width: 90%;
  display: block;
  margin: 15px auto;
  border-radius: 15px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 1) 0 0 3vw;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.bem_person_m img {
  width: 100%;
}

.bem_person_m .info {
  background-color: hsl(0, 0%, 33%);
  color: white;
  font-weight: bold;
  padding: 5px;
  display: block;
  margin: 0 auto;
}

.bem_person_m .info h1 {
  margin: 0;
  text-align: center;
  font-size: 6vw;
}

.bem_person_m .info h2 {
  margin: 5px 0;
  text-align: center;
  font-size: 4.5vw;
  opacity: 0.75;
  font-weight: normal;
}

.bem_person_m .img_container {
  width: calc(100% - 30px);
  border-radius: 10px 10px 0 0;
  margin: 15px 15px 0 15px;
  overflow: hidden;
  position: relative;
}

.bem_person_m .tap_for_info {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #00b4b0;
  font-weight: bold;
  font-size: 4.5vw;
  background-color: white;
  margin: 0;
  padding: 2vw 0 2vw 2vw;
  border-radius: 6vw 0 0 0;
}

.bem_person_m .tap_for_info svg {
  display: inline-block;
  height: 4.5vw;
  vertical-align: middle;
  fill: #00b4b0;
}
</style>