<template>
  <div class="szolgaltatasok">
    <h1>Belgyógyászat</h1>
    <ul>
      <li>Általános betegvizsgálatok</li>
      <li>Betegségmegelőző szaktanácsadás</li>
      <li>Vakcinázási programok</li>
    </ul>

    <h1>Sebészet</h1>
    <h2>Lágyszervi műtétek</h2>
    <ul>
      <li>Ivartalanítások</li>
      <li>Daganateltávolítások</li>
      <li>Baleseti sérülések sebészi ellátása</li>
      <li>Szemészeti műtétek</li>
      <li>Húgyhólyag- és húgyuti műtétek</li>
      <li>Bélmetszés</li>
      <li>Idegentest-eltávolítás</li>
      <li>Toklász eltávolítása</li>
    </ul>

    <h1>Bőrgyógyászat</h1>
    <ul>
      <li>Szőrhullás, valamint allergiás bántalmak diagnosztikája, egyedi allergiatesztek vérből.</li>
      <li>Gombás, bakteriális, demodicosisos bőrgyulladások vizsgálata és gyógykezelése</li>
      <li>Fülgyulladások terápiája</li>
    </ul>

    <h1>Szülészeti beavatkozások</h1>
    <ul>
      <li>Nehézellés levezetése</li>
      <li>Császármetszés</li>
      <li>Torz magzatok műtéti eltávolítása</li>
    </ul>

    <h1>Fogászati beavatkozások</h1>
    <ul>
      <li>Fogváltás ellenőrzése</li>
      <li>Sérült, elhalt fog eltávolítása</li>
      <li>Fogkőeltávolítás (ultrahangos depuratio)</li>
      <li>Fogínygyulladás kezelése</li>
    </ul>

    <h1>Kiegészítő vizsgálatok</h1> 
    <ul>
      <li>Vérvételek</li>
      <li>Mikroszkópos vizsgálatok</li>
      <li>Szövettani minták</li>
      <li>Antibiotikum-érzékenységi mintavételek</li>
      <li>Gyorstesztek</li>
    </ul>
    <p><i>
      A legtöbb vizsgálatot saját laborunkban tudjuk elvégezni, de közvetlen kapcsolatban állunk budapesti központi laborokkal
      is. a Munkánkat jó minőségű ultrahangkészülék segíti, röntgenkészülék jelenleg még nem áll rendelkezésre.
    </i></p>

    <h1>Állatok egyedi jelölése</h1>
    <ul>
      <li>Mikrochip beültetése</li>
      <li>Állatútlevél készítése</li>
      <li>Regisztráció a <a href="https://www.petvetdata.hu/" target="_blank">Magyar Állatorvosi Kamara kisállat-nyilvántartó rendszer</a>ében</li>
    </ul>
  </div>
</template>

<style scoped>
@font-face {
  font-family: "Open Sans";
  src: url("~@/assets/fonts/openSans/OpenSans-Medium.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("~@/assets/fonts/openSans/OpenSans-Bold.ttf");
  font-weight: bold;
}

.szolgaltatasok {
  display: block; margin-left: auto; margin-right: auto;
  height: 500px;
  width: 90%;
  max-width: 1000px;
  height: max-content;
  border-radius: 0 0 15px 15px;
  padding: 15px;
  background-image: url("~@/assets/backgrounds/bgWhite.png");
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;

  /* Szöveg */
  text-shadow: rgb(255, 255, 255) 0 0 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1em;
}

h1 {
  margin-top: 50px;
  margin-bottom: 0;
  padding: 5px;
  border-radius: 15px;
  color: white;
  text-shadow: none;
  background-color: rgba(36,61,66);
  width: max-content;
  max-width: 100%;
  font-size: 32px;
}

h2 {
  margin: 0;
  margin-left: 15px;
  font-size: 24px;
}

p {
  margin: 5px 5px 5px 50px;
}

ul {
  margin: 0 0 0 50px;
  padding: 0;
}

a {
  color: #58c5c2;
}

a:hover {
  text-shadow: hsl(178, 48%, 25%) 0 0 5px;
  background-color: rgba(88, 197, 193, 0.5);
  color: white;
  text-decoration: none;
}

a:active {
  background-color: #58c5c2;
  text-shadow: none;
  color: black;
  text-decoration: none;
}

@media only screen and (max-width: 465px) {
  .szolgaltatasok {
    font-size: 1em;
    padding: 5px;
    margin: auto;
    width: 93%;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }
}
</style>