<template>
  <ImagePreview
    v-model:is-open="preview.isOpen"
    :image-path="preview.imagePath"
    :image-alt="preview.imageAlt"
  />

  <div class="kezdolap">
    <div id="intro">
      <p style="margin: 0"><b>Kedves Pácienseink!</b></p>

      <p style="margin: 0">
        Az Állatszerviz Kft. rendelője 2007 óta működik. Intézményünk folyamatosan fejlődve
        jelenleg az Árpád utca 8-ban található. Önálló, zárt parkoló, két vizsgálóhelyiség, műszeres
        diagnosztikai vizsgáló, műtő, valamint labor áll partnereink rendelkezésére. Igyekeztünk
        úgy megtervezni és kialakítani mindent, hogy a gazdik és kedvenceik kényelmét szolgálja. Új
        munkatársakkal is gazdagodtunk, akik szintén magas szaktudással és türelemmel állnak
        rendelkezésükre! A rövid várakozási időt kizárólag időpont egyeztetés alapján, 2
        állatorvos és 4 asszisztens segítségével tudjuk biztosítani!
      </p>
      <p style="margin: 0"><b>Köszönjük megtisztelő bizalmukat!</b></p>

      <p style="margin: 15px 0 0 0"><b>Az Állatszerviz csapata</b></p>
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="50" id="scroll_down_arrow" @click="scrollToPictures">
      <defs>
        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="5" result="blur" />
          <feOffset in="blur" dx="0" dy="0" result="offsetBlur" />
          <feMerge>
            <feMergeNode in="offsetBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <rect x="-100%" y="-100%" width="400%" height="400%" fill="#00000000" />
      <polygon id="triangle" points="0,0 100,50 200,0" fill="#42FFF8" filter="url(#shadow)" />
    </svg>



    <div id="pictures">
      <h1>Képek</h1>

      <div id="pictures_container">
        <div class="picture_container" v-for="(image, index) in images" :key="index">
          <img
            :src="image.source"
            :alt="image.alt"
            class="picture_rand_rot"
            @click="openImagePreview(image.source, image.alt)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePreview from "@/components/ImagePreview.vue"

export default {
  components: {
    ImagePreview
  },
  data() {
    return {
      // Itt azért van adatokkal reprezentálva, máshol meg csak a HTML-ben komponensek beszúrva a különböző adatokkal, mert itt az image preview-nak
      // hivatkoznia kell az adatokra, amit a komponens copypaste-es megoldással nem lehetne megoldani.
      images: [
        { source: require("@/assets/buildingImages/belter_varoVendegMosdo.jpg"), alt: "Beltér - Váró, vendég mosdó" },
        { source: require("@/assets/buildingImages/belter_varo.jpg"), alt: "Beltér - Váró" },
        { source: require("@/assets/buildingImages/belter_1VizsgaoHelyiseg.jpg"), alt: "Beltér - I. vizsgáló helyiség" },
        { source: require("@/assets/buildingImages/belter_muto.jpg"), alt: "Beltér - Műtő" },
        { source: require("@/assets/buildingImages/belter_2VizsgaoHelyiseg.jpg"), alt: "Beltér - II. vizsgáló helyiség" },
        { source: require("@/assets/buildingImages/kulter_bejarat.jpg"), alt: "Kültér - Bejárat" },
        { source: require("@/assets/buildingImages/kulter_parkolo.jpg"), alt: "Kültér - Parkoló" },
        { source: require("@/assets/buildingImages/kulter_epuletAzUtcarol.jpg"), alt: "Kültér - Épület az utcáról" },
      ],
      preview: {
        isOpen: false,
        imagePath: "",
        imageAlt: ""
      },
    };
  },
  methods: {
    scrollToPictures() {
      document.getElementById("pictures").scrollIntoView({behavior: "smooth"});
    },
    openImagePreview(imagePath, imageAlt) {
      this.preview.imagePath = imagePath;
      this.preview.imageAlt = imageAlt;
      this.preview.isOpen = true;
    }
  },
  watch: {
    "preview.isOpen"(newValue) {
      if (newValue) {
        document.body.classList.add("disable_scroll");
      } else {
        document.body.classList.remove("disable_scroll");
      }
    },
  },
  mounted() {
    const ROTATION_THRESHOLD = 15; // fok
    const PICTURES = document.getElementsByClassName('picture_rand_rot');

    for (let i = 0; i < PICTURES.length; i++) {
      const ROTATION = Math.floor(Math.random() * (2 * ROTATION_THRESHOLD + 1)) - ROTATION_THRESHOLD;
      PICTURES[i].style.transform = `rotate(${ROTATION}deg)`;
    }
  }
}
</script>

<style scoped>
  @font-face {
    font-family: "Inter";
    src: url("~@/assets/fonts/inter/Inter-Bold.ttf");
  }

  #intro {
    /* Poizíció és méret */
    display: block;
    margin: auto;
    margin-top: 25px;
    border-radius: 15px;
    width: calc(100% - 4*15px);
    padding: 5px 15px;
    max-width: 1000px;

    background-color: white;
    background-image: url("~@/assets/backgrounds/introBG.png");
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: 6em;
    background-position: 100% 100%;

    /* Árnyék */
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;

    /* Szöveg beállítások */
    font-size: 21px;
    text-align: center;
    line-height: 1.33em;
  }

  #intro p {
    text-shadow: white 0 0 2px; /* A háttérképre legyen kis kontrasztja */
  }

  #scroll_down_arrow {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    transition: transform 0.2s;
  }

  #scroll_down_arrow #triangle {
    fill: #42FFF8;
    transition: fill 0.2s, transform 0.2s;
  }

  #scroll_down_arrow:hover {
    transform: scale(1.25);
  }

  #scroll_down_arrow:hover #triangle {
    fill: white;
  }

  #scroll_down_arrow {
    cursor: pointer;
  }

  #pictures {
    background-image: url("~@/assets/backgrounds/bgGray.png");
    background-color: #e7e8e9;
    margin-top: min(20vh, 300px);
    width: 100%;
  }

  #pictures h1 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    color: hsl(0, 0%, 50%);
    text-shadow: white 0 0 15px; /* Kontrasztért a háttéren lévő sötétebb állat körvonalakkal szemben */
    text-align: center;
    padding-top: 15px;
  }

  #pictures_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
  }

  .picture_container {
    width: 500px;
    margin: 30px;
  }

  .picture_container img {
    width: 100%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;
    border: white solid 3px;
    cursor: pointer;

    transition: scale 0.2s;
  }

  .picture_container img:hover {
    scale: 1.1;
    border: #42FFF8 solid 3px;
    box-shadow: #42FFF8 0 0 15px;
  }
</style>