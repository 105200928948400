<template>
  <a :class="{paper: true, disturbing_warn_open: (disturbingWarnOpen && disturbing)}" :style="'margin-top: ' + marginTop + 'px;'" :href="paperPath" target="_blank" @click="handleClick" @mouseleave="handleMouseLeave">
    <svg class="disturbing_icon" v-if="disturbing" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" id="mainIconPathAttribute" fill="#ff0000"></path> </svg>
    <img class="thumbnail" :src="thumbnailPath" :alt="title">
    <p class="title">{{title}}</p>

    <div class="disturbing_warn"> <!-- Ha v-if fel elrejtem, ha nem tartalmaz a dokumentum felkavaró képeket, nem működik a transition, szóval ezt a két elemnyi felesleges forrásokat elvesztegetjük -->
      <p>Ez a dokumentum felkavaró képeket tartalmaz. Biztosan megnyitja?<br><br>Kattintson még egyszer a megnyitáshoz!</p>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    thumbnailPath: {
      type: String,
      requred: true
    },
    title: {
      type: String,
      requred: true
    },
    paperPath: {
      type: String,
      requred: true
    },
    disturbing: {
      type: Boolean,
      requred: true
    },
    marginTop: {
      type: Number,
      requred: true
    }
  },
  data() {
    return {
      disturbingWarnOpen: false
    }
  },
  methods: {
    handleClick(event) {
      if (this.disturbing) {
        if (!this.disturbingWarnOpen) {
          event.preventDefault();
          this.disturbingWarnOpen = true;
        }
      }
    },

    handleMouseLeave() {
      this.disturbingWarnOpen = false;
    }
  }
}
</script>

<style scoped>
  .paper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: 25px 15px;
    padding: 15px;
    text-align: center;
    width: 300px;
    border-radius: 15px;
    text-decoration: none;

    transition: scale 0.2s;
  }

  .paper .thumbnail {
    width: 256px; /* Adjust as needed */
    margin-bottom: 10px;
    box-shadow: black 0 0 5px;
  }

  .paper .title {
    margin: 0;
    display: block;
    color: #58c5c2;
    font-weight: bold;
    text-shadow: white 0 0 5px;  
  }

  .paper:hover {
    background-color: #58c5c2;
    scale: 1.1;
    box-shadow: black 0 0 5px;
  }

  .paper:hover .thumbnail {
    box-shadow: none;
  }

  .paper:hover .title {
    color: white;
    text-shadow: none;
  }

  /* Felkavaró figyelmeztetés */

  .disturbing_icon {
    position: absolute;
    top: 5px;
    right: 2px;
    left: auto;
    z-index: 2;
    scale: 1;

    transition: top 0.5s, right 0.5s, scale 0.5s;
  }

  .disturbing_icon path {
    fill: hsl(0, 100%, 65%);

    transition: fill 0.5s;
  }

  .disturbing_warn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: hsl(0, 100%, 65%);

    opacity: 0;
    transition: opacity 0.5s;
  }

  .disturbing_warn p {
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-top: 150px;
  }

  /* Megnyitott */

  .disturbing_warn_open {
    background-color: #58c5c2;
    scale: 1.1;
    box-shadow: black 0 0 5px;
  }

  .disturbing_warn_open .disturbing_icon {
    scale: 4;
    top: 60px;
    right: 148px; /* Azért van hardcode-olva, mert ha `auto`-ra rakom (jobb és bal oldalt is, hogy középen legyen), akkor nem működik a transition */
  }

  .disturbing_warn_open .disturbing_icon path {
    fill: white
  }

  .disturbing_warn_open .disturbing_warn {
    opacity: 1;
  }
</style>