<template>
  <div class="elerhetoseg">
    <div style="width: max-content; display: block; margin-left: auto; margin-right: auto;">
      <h1>Állatszerviz Kft.<span id="rendelo_span"> Állatorvosi rendelő</span></h1>
      <h2>Zalaegerszeg, Árpád u. 8.</h2>
      <hr>
      <div class="contact_options">
        <a href="https://goo.gl/maps/1yR2vMRgpRjhTFCn8" title="Cím" target="_blank">
          <div class="contact_icon_container">
            <svg class="contact_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
          </div>
          Zalaegerszeg, Árpád u. 8.
        </a>
        <a href="http://www.allatszervizkft.hu/" title="Weboldal" target="_blank">
          <div class="contact_icon_container">
            <svg class="contact_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/></svg>
          </div>
          www.allatszervizkft.hu
        </a>
        <a href="tel:+36305085956" title="Telefonszám">
          <div class="contact_icon_container">
            <svg class="contact_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
          </div>
          +36 30 508 5956
        </a>
        <a href="mailto:allatszervizkftzeg@gmail.com" title="E-mail cím">
          <div class="contact_icon_container">
            <svg class="contact_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
          </div>
          allatszervizkftzeg@gmail.com
        </a>
        <a href="https://www.facebook.com/people/%C3%81llatszerviz-Kft/100040696402231/" title="Facebook oldal" target="_blank">
          <div class="contact_icon_container">
            <svg class="contact_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>
          </div>
          Állatszerviz Kft.
        </a>
      </div>
      <hr>
      <div id="opening_hours">
        <h1>Nyitvatartás</h1>
        <h3 id="precall_disclaimer">Előzetes telefonos bejelentkezés szükséges!</h3>
        <table id="opening_hours_table">
          <tr>
            <td class="day_td">Hétfő</td>
            <td class="opening_hours_td">8:00 - 18:00</td>
          </tr>
          <tr>
            <td class="day_td">Kedd</td>
            <td class="opening_hours_td closed">Zárva</td>
          </tr>
          <tr>
            <td class="day_td">Szerda</td>
            <td class="opening_hours_td">8:00 - 16:30</td>
          </tr>
          <tr>
            <td class="day_td">Csütörtök</td>
            <td class="opening_hours_td">10:00 - 18:00</td>
          </tr>
          <tr>
            <td class="day_td">Péntek</td>
            <td class="opening_hours_td">8:00 - 18:00</td>
          </tr>
          <tr>
            <td class="day_td weekend">Szombat</td>
            <td class="opening_hours_td closed">Zárva</td>
          </tr>
          <tr>
            <td class="day_td weekend">Vasárnap</td>
            <td class="opening_hours_td closed">Zárva</td>
          </tr>
        </table>
      </div>
      <hr>
    </div>
    <iframe id="google_maps_embed" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1364.7914009305287!2d16.846943!3d46.832216!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47692803362bb753%3A0xdbc1ee19654a5005!2s%C3%81llatszerviz%20Kft.!5e0!3m2!1shu!2shu!4v1688730565139!5m2!1shu!2shu" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
  </div>
</template>

<style scoped>
  @font-face {
    font-family: "Open Sans";
    src: url("~@/assets/fonts/openSans/OpenSans-Medium.ttf");
    font-weight: normal;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("~@/assets/fonts/openSans/OpenSans-Bold.ttf");
    font-weight: bold;
  }

  .elerhetoseg {
    background-image: url("~@/assets/backgrounds/bgWhite.png");
    background-color: white;
    padding: 5px 10px 20px 10px;
    width: 820px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;
    border-radius: 0 0 15px 15px;
    padding-top: 20px;
  }

  h1 {
    margin: 0;
    font-weight: bold;
  }

  h2 {
    margin: 0;
    opacity: 0.75;
    font-weight: normal;
  }

  #precall_disclaimer {
    margin: 0;
    color: hsl(0, 90%, 66%);
  }

  hr {
    border-color: hsl(0, 0%, 75%);
    border-style: solid;
    color: hsl(0, 0%, 75%);
  }

  .contact_options > * {
    display: block;
    padding: 3px 2px;
    text-decoration: none;
    color: #00b4b0;
    width: max-content;
    font-weight: bold;
    text-shadow: white 0 0 3px;
  }

  .contact_options > a:hover {
    background-color: rgba(88, 197, 193, 0.5);
    text-shadow: hsl(178, 48%, 25%) 0 0 5px;
    color: white;
  }

  .contact_options > a:hover svg {
    filter: drop-shadow(0 0 5px hsl(178, 48%, 25%));
    fill: white;
  }

  .contact_options > a:active {
    background-color: #58c5c2;
    text-shadow: none;
    color: black;
  }

  .contact_options > a:active svg {
    filter: none;
    fill: black;
  }

  .contact_icon_container {
    float: left;
    width: 30px;
  }

  .contact_icon {
    height: 18px;
    display: block;
    margin-left: auto;
    margin-right: 5px;
    fill: #00b4b0
  }

  #google_maps_embed {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: hsl(0, 0%, 75%) solid 1px;
    max-width: 800px;
    width: 100%;
    height: 400px;
  }

  #opening_hours {
    width: max-content;
    display: block;
    margin-right: auto;

    font-family: 'Open Sans', sans-serif;
  }

  #opening_hours_table td {
    margin: 0;
    padding: 0;
  }

  #opening_hours .day_td {
    font-weight: bold;
    padding-right: 5px;
  }

  #opening_hours .day_td.weekend {
    color: hsl(0, 100%, 33%);
  }

  #opening_hours .opening_hours_td.closed {
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: rgba(255, 0, 0, 0.5);
    text-shadow: red 0 0 3px;
  }

  h1, h2, h3, p {
    max-width: 90vw;
  }

  @media only screen and (max-width: 890px) {
    .elerhetoseg {
      width: 90%;
    }
  }

  @media only screen and (max-width: 610px) {
    #rendelo_span {
      display: none;
    }
  }
</style>