<template>
  <div id="bem_person_modal" v-show="isOpen">
    <div class="window">
      <div class="top_bar">
        <button class="close_button" title="Bezárás" style="padding: 5px" @click="close()">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" id="IconChangeColor">
            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" id="mainIconPathAttribute"/>
            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" id="mainIconPathAttribute"/>
          </svg>
        </button>
        <h1>{{name}}</h1>
        <h2>{{role}}</h2>
      </div>
      <div class="slot" v-html="html"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    html: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    }
  },
  computed: {
    imageName() {
      return this.imagePath.split('/').pop();
    }
  }
}
</script>

<style>
#bem_person_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.75);
}

#bem_person_modal .window {
  width: 95vw;
  height: 90vh;
  background-color: white;
  border-radius: 15px;
  overflow-x: hidden;
}

#bem_person_modal .top_bar {
  width: calc(95vw - 10px);
  padding: 5px;
  height: max-content;
  background-color: hsl(0, 0%, 33%);
  position: absolute;
  border-radius: 15px 15px 0 0;
}

#bem_person_modal .close_button {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  color: white;
  display: block;
  margin-left: auto;
  cursor: pointer;
}

#bem_person_modal .close_button:active {
  background-color: red;
}

#bem_person_modal .top_bar h1 {
  margin: 0;
  text-align: center;
  font-size: 190%;
  width: 100%;
  color: white;
}

#bem_person_modal .top_bar h2 {
  margin: 5px 0 0 0;
  text-align: center;
  font-size: 133%;
  color: hsla(0, 0%, 100%, 0.75);
  font-weight: normal;
}

#bem_person_modal .slot {
  font-size: 1.1em;
  text-align: left;
  margin-top: 150px;
  padding: 5px;
  background-color: transparent;
}

#bem_person_modal .slot p {
  margin-top: 0;
}
</style>