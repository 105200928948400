<template>
  <nav class="navbar">
    <img src="../assets/navbar/navbarAnimals.png" id="animals">
    <h1 id="page_title" v-if="areLinksCompact">{{currentPageName}}</h1>
    <NavbarLinks @compactModeUpdated="linksCompactModeUpdated"/>
  </nav>
</template>

<script>
import NavbarLinks from './NavbarLinks.vue';

export default {
  name: 'Navbar',
  components: {
    NavbarLinks
  },
  props: [
    "currentPageName"
  ],
  data() {
    return {
      areLinksCompact: false
    }
  },
  methods: {
    linksCompactModeUpdated(isCompact) {
      this.areLinksCompact = isCompact
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: "Rubik";
  src: url("~@/assets/fonts/rubik/Rubik-SemiBold.ttf");
}

.navbar {
  background-color: #00424d;
  height: 50px;
  box-shadow: black 0 0 15px;
  user-select: none;
  position: relative;
  z-index: 2;
}

#animals {
  height: 50px;
  float: left;
  margin-left: 5px;
  display: block;
}

.links_overflowing {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 10px 0 0 0;
  height: 100%;
}

#page_title {
  color: hsla(189, 100%, 95%, 0.75);
  margin: 0;
  float: left;
  width: max-content;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@media only screen and (max-width: 1210px) {
  #animals {
    display: none;
  }
}
</style>
