<template>
  <div :class="{gyik_accordion: true, expanded: expanded}">
    <button class="header_button" @click="toggleExpanded">
      <h1 class="header_text">{{headerText}}</h1>
      <svg class="dropdown_arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16" id="IconChangeColor"> <path fill-rule="evenodd" d="M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z" id="mainIconPathAttribute"></path> </svg>
    </button>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerText: {
      type: String,
      requred: true
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    }
  }
}
</script>

<style scoped>
  ol {
    list-style-type: none;
    counter-reset: my-counter;
  }

  ol li::before {
    content: counter(my-counter) ".";
    counter-increment: my-counter;
    font-weight: bold;
    margin-right: 0.5em;
  }

  .gyik_accordion {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    width: calc(100% - 20px);
  }

  .header_button {
    border: none;
    background-color: #00424d;
    color: hsla(189, 100%, 95%, 0.75);
    cursor: pointer;
    padding: 5px;
    width: 100%;
    margin: 0;
    border: transparent solid 2px;
    border-bottom: none;
    transition: background-color 0.5s, border-color 0.5s;
  }

  .expanded .header_button {
    background-color: hsl(189, 51%, 43%);
    border-color: hsl(189, 51%, 33%);
  }

  .header_text {
    float: left;
    margin: 0;
    text-align: left;
    max-width: calc(100% - 35px);
  }

  .dropdown_arrow {
    float: right;
    margin-top: 7px;
    margin-right: 7px;
    transform: scaleY(-1);
    transition: transform 0.5s;
  }

  .expanded .dropdown_arrow {
    transform: scaleY(1);
  }

  .content {
    margin: 0 0 5px 0;
    padding: 5px;
    color: hsl(0, 0%, 33%);
    background-color: hsl(0, 0%, 95%);
    text-align: justify;
    line-height: 1.2em;
    height: max-content;
    max-height: 0px;
    transform-origin: top;
    transform: scaleY(0);
    border: hsl(0, 0%, 80%) solid 2px;
    border-top: none;
    transition: transform 0.66s, max-height 0.66s ease-out;
  }

  .expanded .content {
    margin-top: 0;
    max-height: 1500px;
    transform: scaleY(1);
    transition: transform 0.66s, max-height 0.66s ease-in;
  }

  :slotted(a) { 
    color: #00b4b0;
  }

  :slotted(a:hover) { 
    text-shadow: hsl(178, 48%, 25%) 0 0 5px;
    background-color: rgba(88, 197, 193, 0.5);
    color: white;
    text-decoration: none;
  }

  :slotted(a:active) { 
    background-color: #58c5c2;
    text-shadow: none;
    color: black;
    text-decoration: none;
  }

  :slotted(p) {
    margin: 10px 0;
    text-indent: 10px;
  }

  :slotted(h2) { 
    margin: 15px 0 0 10px;
  }

  :slotted(hr) { 
    color: transparent;
    border: hsl(0, 0%, 80%) solid 1px;
    width: 100%;
  }

  :slotted(ul) {
    margin-top: 15px;
  } 

  :slotted(ol li::marker) { 
    font-weight: bold;
  }

  :slotted(ul) { 
    list-style-type: none;
    padding-left: 22px;
  }
</style>