<template>
  <div class="bemutatkozas_person">
    <div class="horizontal_layout">
      <div class="left">
        <img class="portrait" :src="require('@/assets/employees/' + image)" :alt="name">
      </div>
      <div class="right">
        <h1 class="name">{{name}}</h1>
        <h2 class="role">{{role}}</h2>
        <div class="slot" v-html="html"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "image", // A `@/assets/employees/` mappához relatív, kiterjesztést tartalmazó fájlnév kell
    "name",
    "role",
    "html"
  ]
}
</script>

<style> /* Nem lehet scoped, mert akkor nem vonatkozik a slot-ra */
  .bemutatkozas_person {
    padding: 15px;
    background-color: white;
    width: calc(100% - 100px);
    display: block;
    margin: 15px auto;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px;
  }

  .bemutatkozas_person .slot {
    font-size: 1.1em;
    line-height: 1.25;
  }

  .bemutatkozas_person .slot li {
    margin: 10px 0;
  }

  .bemutatkozas_person .slot > * {
    margin-top: 0;
  }

  .bemutatkozas_person .horizontal_layout {
    display: inline-block;
    width: 100%;
  }

  .bemutatkozas_person .horizontal_layout > * {
    display: inline-block;
  }

  .bemutatkozas_person .horizontal_layout .left {
    width: 300px;
    margin-right: 15px;
  }

  .bemutatkozas_person .horizontal_layout .right {
    vertical-align: top;
    max-width: calc(100% - 350px);
  }

  .bemutatkozas_person .portrait {
    width: 100%;
    border-radius: 15px;
  }

  .bemutatkozas_person .name {
    margin: 0;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
  }

  .bemutatkozas_person .role {
    margin: 0;
    font-weight: normal;
    font-size: 20px;
    color: gray;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 1100px) {
    .bemutatkozas_person {
      padding: 0;
      background-color: transparent;
      width: calc(100% - 10px);
      display: block;
      margin: 0;
      padding: 5px;
      padding-bottom: 50px;
      border-radius: 0;
      box-shadow: none;
    }
  }
</style>