<template>
  <div class="ceginfo">
    <h1>Végzés</h1>
    <p>
      A Zala Megyei Bíróság mint Cégbíróság a(z) <b> ÁLLATSZERVIZ Állatgyógyászati Korlátolt
      Felelősségű Társaság </b> kérelmére, egyszerűsített cégeljárás keretében elrendeli a cég
      bejegyzését a Cg.20-09-068060 számú cégjegyzékbe az alábbi adatokkal:
    </p>

    <h2>1. Általános adatok</h2>
    <p>Bejegyezve: 2007. november 14.</p>
    <p>Cégforma: Korlátolt felelősségű társaság </p>

    <h2>2. A cég elnevezése</h2>
    <p><u>2/001</u> ÁLLATSZERVIZ Állatgyógyászati Korlátolt Felelősségű Társaság. </p>

    <h2>3. A cég rövidített elnevezése(i)</h2>
    <p><u>3/001</u> ÁLLATSZERVIZ Kft. </p>

    <h2>5. A cég székhelye</h2>
    <p><u>5/001</u> 8900 Zalaegerszeg, Árpád u. 6. </p>

    <h2>8. A létesítő okirat kelte</h2>
    <p><u>8/001</u> 2007.11.12 </p>

    <h2>9. A cég tevékenysége</h2>
    <p><u>9/001</u> 8520'03 Állat-egészségügyi ellátás </p>
    <h3>Főtevékenység</h3>
    <p><u>9/002</u> 5231'03 Gyógyszer-kiskereskedelem </p>

    <h2>13. A képviseletre jogosult(ak) adatai</h2>
    <p><u>13/001</u> A képviselet módja: önálló.</p>
    <p>
      Dr.Matyovszky Balázs Mátyás vezető tisztségviselő (ügyvezető) (an.: Farkas Ibolya)<br>
      8900 Zalaegerszeg, Árpád u. 6.<br>
      Jogv. kezdete: 2007.11.12. 
    </p>

    <h2>20. A cég statisztikai számjele</h2>
    <p><u>20/001</u> 14097953-8520-113-20 </p>

    <h2>21. A cég adószáma</h2>
    <p><u>21/001</u> 14097953-2-20</p>
    <p>Közösségi adószáma: HU14097953</p>
    <p>Közösségi adószám érvényesség kezdete: 2007.11.14.</p>

    <h2>45. A cég elektronikus elérhetősége</h2>
    <p><u>45/001</u> A cég e-mail címe: allatszerviz@freemail.hu </p>

    <h2>49. A cég cégjegyzékszáma és a nyilvántartását vezető bíróság</h2>
    <p><u>49/001</u> 20-09-068060 Vezetve a Zala Megyei Bíróság mint Cégbíróságnál. </p>
    <h3>1(09) A tag(ok) adatai</h3>
    <p>
      1/001 Dr.Matyovszky Balázs Mátyás (an.: Farkas Ibolya)<br>
      8900 Zalaegerszeg, Árpád u. 6.<br>
      Tags. kezdete: 2007.11.12. 
    </p>
    <p style="margin-top: 15px;">
      1/002 Horváth Adrienn (an.: Hajdics Mária)<br>
      8900 Zalaegerszeg, Árpád u. 4.<br>
      Tags. kezdete: 2007.11.12. 
    </p>

    <hr>

    <div id="intial_details">
      <p>
        E végzés ellen fellebbezésnek nincs helye.
      </p>
      <p>
        A per megindításának a bejegyző végzés Cégközlönyben való közzétételétől számított harminc
        napon belül van helye. A határidő elmulasztása jogvesztéssel jár.
      </p>
      <p>
        A 2006. évi V. tv. 3. számú mellékletének I. pontjában felsorolt okiratok vonatkozásában a
        jogi képviselő a bejegyzési kérelemben nyilatkozott, hogy a csatolt okiratok törvényességi
        szempontú vizsgálatát elvégezte.
      </p>
      <p>
        A 2006. évi V. tv. 3. számú mellékletének II. pontjában meghatározott okiratok nem képezik a
        cégbejegyzési kérelem mellékletét, ezek törvényességi szempontú vizsgálatát a jogi képviselő
        végezte.
      </p>
      <p>
        A bejegyzési kérelemhez csatolt, valamint a 2006. évi V. tv. 3. számú mellékletének II.
        pontjában meghatározott okiratokat a 2006. évi V. tv. 37. § (2) bekezdés rendelkezése
        szerint a jogi képviselő őrzi.
      </p>
      <p>
        A cég a gazdasági tevékenység folytatásához szükséges hatósági engedélyét - legkésőbb a
        tevékenység megkezdésével egyidejűleg - választása szerint a Cégközlönyben, vagy a cég
        honlapján köteles közzétenni.
      </p>
      <p>
        A cég székhelye a cég bejegyzett irodája. A bejegyzett iroda a cég levelezési címe, az a
        hely, ahol a cég üzleti és hivatalos iratainak átvétele, érkeztetése, őrzése, rendelkezésre
        tartása, valamint ahol a külön jogszabályban meghatározott, a székhellyel összefüggő
        kötelezettségek teljesítése történik. A cégnek a székhelyét cégtáblával kell megjelölnie. A
        cég létesítő okirata úgy is rendelkezhet, hogy a cég székhelye egyben a központi ügyintézés
        (döntéshozatal) helye. Amennyiben a cég székhelye nem azonos a központi ügyintézés helyével,
        a központi ügyintézés helyét a létesítő okiratában és a cégjegyzékben fel kell tüntetni.
      </p>
      <p>
        Figyelmezteti a bíróság a társaságot arra, hogy a társaság a 2006. évi V. tv. 7. § (1)
        bekezdése szerint a székhelyét cégtáblával köteles megjelölni.
      </p>
      <p>
        A korlátolt felelősségű társaság és a részvénytársaság a bejegyzését követően írásbeli
        képviselete, illetve hivatalos levelezése során - ideértve az elektronikus levelezést is -,
        valamint a honlapján köteles feltüntetni a céget nyilvántartó cégbíróság nevét, a cég nevét
        és székhelyét, a cég cégjegyzékszámát, továbbá szükség szerint a cég felszámolására, illetve
        végelszámolására utaló toldatot.
      </p>
      <p>
        A cég - választása szerint - feltüntetheti a jegyzett tőkéjét is. Ebben az esetben azonban a
        cégjegyzékben szereplő jegyzett tőke feltüntetése mellett meg kell jelölni annak ténylegesen
        rendelkezésre bocsátott összegét is. (2006. évi V. tv. 63. § (2) bekezdés.)
      </p>
      <p>
        A társaság a kérvényen 15.000.-Ft eljárási illetéket elektronikus úton megfizetett.
      </p>
      <p>
        A fenti adat(ok) bejegyzése és közzététele a következő okirat(ok) alapján történt:
        tagjegyzék; létesítő okirat.<br>Az okirat(ok) a cég cégjegyzékét vezető megtekinthetők.
      </p>
    </div>
  </div>
</template>

<style scoped>
@font-face {
  font-family: "Open Sans";
  src: url("~@/assets/fonts/openSans/OpenSans-Medium.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("~@/assets/fonts/openSans/OpenSans-Bold.ttf");
  font-weight: bold;
}

.ceginfo {
  display: block; margin-left: auto; margin-right: auto;
  height: 500px;
  width: 90%;
  max-width: 1000px;
  height: max-content;
  border-radius: 0 0 15px 15px;
  padding: 15px;
  background-image: url("~@/assets/backgrounds/bgWhite.png");
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;

  /* Szöveg */
  text-shadow: rgb(255, 255, 255) 0 0 5px;
  font-family: 'Open Sans', sans-serif;
}

h1 {
  margin: 0 0 5px 0;
  text-align: center;
}

h2 {
  margin: 50px 0 0 0;
}

h3 {
  margin: 10px 0 15px 0;
}

p {
  margin: 0;
}

hr {
  margin: 15px 0;
  border: none;
  border-top: hsl(189, 29%, 20%) 2px dotted;
}

#intial_details p {
  margin-bottom: 15px;
  text-indent: 15px;
}

</style>