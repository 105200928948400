<template>
  <div class="tudomanyos_munkak">
    <div id="papers_container">
      <Paper
        v-for="paper in papers"
        :key="paper.paperPath"
        :thumbnailPath="paper.thumbnailPath"
        :title="paper.title"
        :paperPath="paper.paperPath"
        :disturbing="paper.disturbing"
        :marginTop="paper.marginTop"
      />
    </div>
  </div>
</template>

<script>
import Paper from "@/components/Paper.vue"

export default {
  components: {
    Paper
  },
  data() {
    return {
      papers: [
        {
          thumbnailPath: require("@/assets/paperThumbnails/szaporodasbiologiai.png"),
          title: "Szaporodásbiológiai gond(olat)ok",
          paperPath: "/publicRes/papers/szaporodasbiologiai.pdf",
          disturbing: true,
          marginTop: 172
        },
        {
          thumbnailPath: require("@/assets/paperThumbnails/szarvasmarhaKokcidiozis.png"),
          title: "Szarvasmarha-kokcidiózis a gyakorló állatorvos szemével",
          paperPath: "/publicRes/papers/szarvasmarhaKokcidiozis.pdf",
          disturbing: true,
          marginTop: 172
        },
        {
          thumbnailPath: require("@/assets/paperThumbnails/paratuberkulozis.png"),
          title: "A paratuberkulózis kártétele és az ellene való védekezés egy hazai nagyüzemi holstein-fríz tehenészetben",
          paperPath: "/publicRes/papers/paratuberkulozis.pdf",
          disturbing: false,
          marginTop: 0
        },
        {
          thumbnailPath: require("@/assets/paperThumbnails/ohv.png"),
          title: "A bal oldali oltógyomor-helyzetváltozás (OHV) hatása a tejtermelés gazdaságosságára",
          paperPath: "/publicRes/papers/ohv.pdf",
          disturbing: false,
          marginTop: 0
        }
      ]
    }
  }
}
</script>

<style scoped>
  .tudomanyos_munkak {
    width: 100%;
    height: max-content;
    margin-top: 50px;
    background-image: url("~@/assets/backgrounds/bgWhite.png");
    background-color: #ffffff;
  }

  #papers_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px 15px 10px 15px;
  }
</style>